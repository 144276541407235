import React from "react"

// Components
import Hero from "../../../components/hero"

const ContactHero = () => {
  return (
    <Hero pageTitle="Contact Us">
      <p>
        Have a general inquiry or need customer support? Team Visby will be happy to field your inquiry here.
      </p>
    </Hero>
  )
}
export default ContactHero
