import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import HubspotForm from "components/hubspot"

// Icons
import Twitter from "assets/icons/logo-twitter.inline.svg"
import LinkedIn from "assets/icons/logo-linked-in.inline.svg"
import Facebook from "assets/icons/logo-facebook.inline.svg"
import YouTube from "assets/icons/logo-youtube.inline.svg"

const StyledContactForm = styled.section`
  padding: 64px 0px 64px 0%;

  ${breakpoint.small`
	  padding-left: 1%;  
  `}
  ${breakpoint.medium`
	  padding: 124px 0px 124px 3%;  
  `}
  ${breakpoint.extraLarge`
	  padding-left: 6%;  
  `}
  ${Container} {
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1%;

    ${breakpoint.medium`
      column-gap: 3%;
	  `}

  }

  .contact__form {
    flex: 1 0 auto;
    max-width: 460px;
    margin-bottom: 25px;
    width: 100%;
  }    

  .social-links {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .social-link {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      a {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.charcoal};
        border-radius: 50%;

        &:hover {
          background-color: ${colors.orange};
        }
      }
    }
  }
`

const ContactForm = () => {

  return (
    <StyledContactForm>
      <Container data-aos="fade-up">
        <div className="contact__form">
        <HubspotForm
            formId='3a7d2571-625e-4edc-ae4a-7b4ed98abad1'
          />
        </div>

        <div className="contact__info">
          <p>
            <strong>Customer Support</strong>
          </p>
          <p>Hours: Mon-Fri 8:00 am - 4:00 pm PST</p>
          <p>Email: <a href="mailto:support@visby.com">support@visby.com</a></p>
          <p>Phone: <a href="tel:+1833-468-4729">1-833-GoVisby</a> (1-833-468-4729)</p>
          <br />
          <p>
            <strong>Headquarters</strong>
          </p>
          <p>
            3010 North First Street
            <br />
            San Jose, CA 95134
          </p>
          <ul className="social-links">
            <li className="social-link">
              <a
                href="https://www.linkedin.com/company/visbymedical/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedIn />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://twitter.com/visbymedical"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <Twitter />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://www.facebook.com/visbymedical/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <Facebook />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://www.youtube.com/channel/UCODvhhPem7t1wJXlWNbgpLg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <YouTube />
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </StyledContactForm>
  )
}

export default ContactForm
