import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/contact/hero"
import Form from "./screens/contact/wpform"

const Contact = () => {
  return (
    <React.Fragment>
      <Hero />
      <Form />
    </React.Fragment>
  )
}
export default Contact

export const Head = () => (
  <Seo
    title="Contact Us"
    description="Have a general inquiry or need customer support? Team Visby will be happy to field your inquiry here."
    image="/meta/contact.jpg"
    recaptcha
    />
)